/* src/global.css */
@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@keyframes glow {
    0%, 100% {
    box-shadow: 0 0 15px rgba(37, 99, 235, 0.75);
    }
    50% {
        box-shadow: 0 0 40px rgba(37, 99, 235, 1);
    }
}

@keyframes scroll {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}